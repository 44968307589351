import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHeaderComponent from "../components/page-header";
import BgImage from "../images/pages/careers.jpg"
import ImgCallCenter from "../images/misc/bg-callcenter.jpg"
import { Container } from "react-bootstrap";

const ContactPage = () => (
    <Layout >
        <div style={{
            backgroundImage: `url(${BgImage})` ,
            backgroundPosition:`center center`,
            backgroundSize:`cover`
        }}>


            <PageHeaderComponent name="Contact us" desc=""/>
        </div>
        <SEO title="Contact Us" />
        <section className="page-section">
            <Container>
                <div className="d-flex mb-5">
                    <div >
                        <h2 className="service-heading">How to reach us</h2>
                        <p className="d-flex"><div style={{ width: `65px` }} className="text-muted">E-mail:</div> <div>info@smartsolution-llc.com</div></p>
                        <p className="d-flex"><div style={{ width: `65px` }} className="text-muted">Phone:</div> <div>+383 49 88 99 88<br />+383 45 14 56 78<br />+44 7979 486 412</div></p>
                        <p className="d-flex"><div style={{ width: `65px` }} className="text-muted">Address:</div> <div>Dardania B9 - B3 nr 8, <br />10000, Prishtina, Kosovo. </div></p>
                    </div>
                </div>


            </Container>
        </section>
    </Layout>
)

export default ContactPage
